import { useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ErrorIcon } from '../../../icons/ErrorIcon';
import Logo from './Logo.png';

const queryParams = new URLSearchParams(window.location.search);
const skipCheck = queryParams.get('skipCheck');
const logoUrl = queryParams.get('logoUrl');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      justifyContent: 'space-between',
    },
    /* 
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
    */
    logoImage: {
      backgroundImage: `url(${logoUrl ? logoUrl : Logo})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '284px',
      height: '284px',
      [theme.breakpoints.down('md')]: {
        width: '200px',
        height: '200px',
      },
    },
  })
);

export function GetStarted() {
  const { nextPane, state } = useAppStateContext();
  const classes = useStyles();
  const handleSkipEQCheck = () => {
    if (window.parent) {
      window.parent.postMessage('equipment-check-skipped', '*');
    }
  };
  return (
    <Container>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item lg={5}>
          {state.appIsExpired ? (
            <>
              <Typography variant="h1" gutterBottom>
                <ErrorIcon />
                App has expired.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Please re-deploy the application and try again.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" gutterBottom>
                Let's get started.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Your online notarization requires a webcam, microphone and quality connection. Let's check your setup to
                ensure a smooth experience.
              </Typography>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={nextPane}
            style={{ marginBottom: '1em' }}
            disabled={state.appIsExpired}
          >
            Get started
          </Button>
          {skipCheck === '1' ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSkipEQCheck}
              style={{ marginLeft: '1em', marginBottom: '1em' }}
              disabled={state.appIsExpired}
            >
              Skip It
            </Button>
          ) : null}
        </Grid>

        <Grid item lg={5}>
          <div className={classes.logoImage} />
          <Typography variant="body1" gutterBottom>
            <strong>Not sure about something?</strong> Visit our&nbsp;
            <a target="_blank" href="https://enotarylog.com/support/" rel="noreferrer">
              FAQs
            </a>{' '}
            for support.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
